import setLocale from 'locales/i18n-config';
import i18nLocaleJsonData from 'locales/generated/en.json';
import moment from 'moment';
import dayjs from 'dayjs';
// import 'moment/locale/en'; -- already loaded by default
import 'jquery'; // eslint-disable-line no-restricted-imports
import 'select2'; // eslint-disable-line no-restricted-imports
// import 'select2/select2_locale_en'; -- already loaded by default

const locale = 'en';
setLocale(locale, i18nLocaleJsonData);
moment.locale(locale);
dayjs.locale(locale);
